var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c(_vm.template, {
    tag: "component"
  }, [_c('v-responsive', {
    staticClass: "center-question py-9 px-6 px-md-3"
  }, [_c('v-layout', {
    attrs: {
      "justify-center": ""
    }
  }, [_c('span', {
    staticClass: "title"
  }, [_vm._v("1:1 문의")])]), _c('router-question')], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }