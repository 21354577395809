<template>
    <v-responsive v-if="question">
        <v-divider class="mt-6"/>
        <v-row>
            <v-col cols="auto">
                제목: {{ question.subject }}
            </v-col>
            <v-spacer />
            <v-col cols="auto">작성일자: {{ $dayjs(question.createdAt).format("YYYY-MM-DD HH:mm:ss") }}</v-col>
        </v-row>
        <v-divider/>
        <v-responsive min-height="200">
            <div>문의내용</div>
            <p v-html="question.content"></p>
        </v-responsive>
        <v-divider/>
        <v-responsive min-height="200">
            <div>답변</div>
            <p v-html="question.answer"></p>
        </v-responsive>
        <v-divider/>
    </v-responsive>
</template>

<script>
import api from "@/api";

export default {
    props: ["_id"],
    data(){
        return {
            question: null
        }
    },
	mounted() {
		this.init();
	},
	methods: {
		async init() {
            var { question } = await api.v1.me.center.questions.get({ _id: this.$route.params._question });
            this.question = question;
		},

        list(){
            this.$router.go(-1);
        },
	},
}
</script>
