<template>
    <v-responsive>
        <v-divider class="mb-4"/>
        <v-text-field v-model="question.subject" outlined label="제목" placeholder=" " hide-details />
        <v-textarea v-model="question.content" outlined label="문의 내용" placeholder=" " hide-details class="mt-4"/>
        <v-divider class="my-4"/>
        <v-layout>
            <v-btn large dark color="grey" @click="cancel">취소</v-btn>
            <v-spacer/>
            <v-btn large color="primary" @click="save">등록</v-btn>
        </v-layout>
    </v-responsive>
</template>

<script>
import api from "@/api";

export default {
	components: {
	},
    data() {
        return {
            files: [{ name: "" },{ name: "" },{ name: "" }],
            fileName: "",
            question: {
                type: null,
                subject: null,
                content: null,
            },
            questionTypes: [
                "회원정보 문의",
                "주문/결제 문의",
                "상품 문의",
                "배송 문의",
                "교환/반품 문의",
                "이벤트/혜택 문의",
                "서비스 개선 의견",
                "기타문의"
            ]
        }
    },
	methods : {
        fileChange(index){
            this.files[index].name = event.target.files[0].name;
        },
        cancel(){
            this.$router.go(-1);
        },
        validate(){
            try{
                var question = this.question;
                // if(!question.type) throw new Error("문의유형을 선택해주세요");
                if(!question.subject) throw new Error("문의제목을 입력해주세요");
                if(!question.content) throw new Error("문의내용을 입력해주세요");

                return true;
            }
            catch(error){
                alert(error.message);
            }
            return false;
        },
        async save(){
            if(this.validate()){
                await api.v1.me.center.questions.post(this.question);
                alert("문의가 등록되었습니다");
                this.$router.go(-1);
            }
        },
	},
}
</script>
