<template>
    <component :is="template">
        <v-responsive class="center-question py-9 px-6 px-md-3">
            <v-layout justify-center><span class="title">1:1 문의</span></v-layout>
            <router-question />
        </v-responsive>
    </component>
</template>
<script>
import RouterQuestion from "@/components/client/center/question/router-question.vue";
export default {
    components: {
        RouterQuestion
    },
    mounted(){
        if(this.logoff) this.$router.push(`/login`);
    },
    computed: {
        template(){
            return () => import(`@/templates/${this.$theme.pages.mypage}`);
        },
        logoff(){
            return !this.$store.state.accessToken;
        }
    }
}
</script>

<style scoped>
.center-question .title {
    font-size: 22px !important;
    font-weight: 600;
}
</style>