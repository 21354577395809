<template>
    <component :is="routerView" />
</template>
<script>
import QuestionList from "./question-list.vue";
import QuestionInput from "./question-input.vue";
import QuestionView from "./question-view.vue";

export default {
    components: {
        QuestionList,
        QuestionInput,
        QuestionView
    },
    computed: {
        routerView(){
            if(-1 < this.$route.path.indexOf("/create")) return "question-input";
            return this.$route.params._question ? "question-view" : "question-list";
        }
    }
}
</script>