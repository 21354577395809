var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.question ? _c('v-responsive', [_c('v-divider', {
    staticClass: "mt-6"
  }), _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_vm._v(" 제목: " + _vm._s(_vm.question.subject) + " ")]), _c('v-spacer'), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_vm._v("작성일자: " + _vm._s(_vm.$dayjs(_vm.question.createdAt).format("YYYY-MM-DD HH:mm:ss")))])], 1), _c('v-divider'), _c('v-responsive', {
    attrs: {
      "min-height": "200"
    }
  }, [_c('div', [_vm._v("문의내용")]), _c('p', {
    domProps: {
      "innerHTML": _vm._s(_vm.question.content)
    }
  })]), _c('v-divider'), _c('v-responsive', {
    attrs: {
      "min-height": "200"
    }
  }, [_c('div', [_vm._v("답변")]), _c('p', {
    domProps: {
      "innerHTML": _vm._s(_vm.question.answer)
    }
  })]), _c('v-divider')], 1) : _vm._e();

}
var staticRenderFns = []

export { render, staticRenderFns }