<template>
    <v-responsive>
        
        <v-divider class="mt-6"/>
        <v-data-table :items="questions" :headers="questionsHeaders" disable-pagination disable-sort hide-default-footer>
            <span slot="item.no" slot-scope="{item}">{{ (page - 1) * limit + +questions.indexOf(item) + 1 }}</span>
            <span slot="item.createdAt" slot-scope="{item}">{{ $dayjs(item.createdAt).format("YYYY-MM-DD HH:mm:ss") }}</span>
            <span slot="item.answer" slot-scope="{item}">
                <span v-if="item.answer">답변</span>
                <span v-else>미답변</span>
            </span>
            <span slot="item.actions" slot-scope="{item}">
                <v-icon small @click="details(item)">{{ mdiForum }}</v-icon>
            </span>
        </v-data-table>
        
        <v-divider />
        <v-pagination v-model="page" :length="pageCount" total-visible="11" @input="search(true)" class="mt-6" />
        <v-layout no-gutters class="ma-1 mt-4">
            <v-spacer/>
            <v-btn :width="$vuetify.breakpoint.mobile ? '100%' : 'auto'" rounded large color="primary" @click="create" class="py-4"><v-icon class="mr-3">{{ mdiChatQuestion }}</v-icon>작성하기</v-btn>
        </v-layout>

    </v-responsive>
</template>

<script>
import api from "@/api";
import { mdiForum, mdiChatQuestion } from "@mdi/js";

import PaginationComponent from "@/components/client/control/pagination-component.vue";

export default {
	components: {
        PaginationComponent
	},
    data() {
        return {
            mdiForum,
            mdiChatQuestion,

            filter: {
                searchKey: 'subject',
                searchValue: '',
                type: ''
            },

            page: 1,
            pageCount: 0,
            limit: 20,

            questions: [],
            questionsHeaders: [
                { text: "no", value: "no", align: "center", width: 50 },
                { text: "문의제목", value: "subject", align: "center" },
                { text: "답변여부", value: "answer", align: "center", width: 100 },
                { text: "문의일자", value: "createdAt", align: "center", width: 180 },
                { text: "-", value: "actions", align: "center", width: 50 },
            ],
            questionTypes: [
                "회원정보 문의",
                "주문/결제 문의",
                "상품 문의",
                "배송 문의",
                "교환/반품 문의",
                "이벤트/혜택 문의",
                "서비스 개선 의견",
                "기타문의"
            ]
        };
    },
	mounted() {
		this.init();
	},
	methods: {
		init() {
            this.search();
            this.$nextTick(() => {
                // 문의내역 - 답변 토글
                (function(){
                    let question = document.querySelectorAll('.board-table--inquiry [role="button"]'),
                    answer = document.querySelectorAll('.board-table--inquiry [role="rowgroup"].answer');

                    for(let i = 0; i < question.length; i++)
                    question[i].addEventListener('click', toggleInquiry);

                    function toggleInquiry(){
                        let idx = this.getAttribute('data-idx'),
                        toggle = document.querySelector('.board-table--inquiry [role="rowgroup"][data-idx-answer="'+idx+'"]');

                        if(toggle !== null){
                            if(toggle.getAttribute('data-answer') === 'opened'){
                                for(let i = 0; i < answer.length; i++)
                                answer[i].removeAttribute('data-answer');
                            } else {
                                for(let i = 0; i < answer.length; i++)
                                answer[i].removeAttribute('data-answer');
                                toggle.setAttribute('data-answer', 'opened');
                            }
                        }
                    }
                })();
            });
		},

        create(){
            this.$router.push(`${this.$route.path}/create`);
        },

        details(question){
            this.$router.push(`${this.$route.path}/${question._id}`)
        },


        async search(){
            var { summary, questions } = await api.v1.me.center.questions.gets({
                headers: {
                    skip: (this.page - 1) * this.limit,
                    limit: this.limit
                },
                params: this.filter
            });

            this.summary = summary;
            this.pageCount = Math.ceil(summary.totalCount / this.limit);
            this.questions = questions;
        },
	},
}
</script>
