var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-responsive', [_c('v-divider', {
    staticClass: "mb-4"
  }), _c('v-text-field', {
    attrs: {
      "outlined": "",
      "label": "제목",
      "placeholder": " ",
      "hide-details": ""
    },
    model: {
      value: _vm.question.subject,
      callback: function ($$v) {
        _vm.$set(_vm.question, "subject", $$v);
      },
      expression: "question.subject"
    }
  }), _c('v-textarea', {
    staticClass: "mt-4",
    attrs: {
      "outlined": "",
      "label": "문의 내용",
      "placeholder": " ",
      "hide-details": ""
    },
    model: {
      value: _vm.question.content,
      callback: function ($$v) {
        _vm.$set(_vm.question, "content", $$v);
      },
      expression: "question.content"
    }
  }), _c('v-divider', {
    staticClass: "my-4"
  }), _c('v-layout', [_c('v-btn', {
    attrs: {
      "large": "",
      "dark": "",
      "color": "grey"
    },
    on: {
      "click": _vm.cancel
    }
  }, [_vm._v("취소")]), _c('v-spacer'), _c('v-btn', {
    attrs: {
      "large": "",
      "color": "primary"
    },
    on: {
      "click": _vm.save
    }
  }, [_vm._v("등록")])], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }