var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-responsive', [_c('v-divider', {
    staticClass: "mt-6"
  }), _c('v-data-table', {
    attrs: {
      "items": _vm.questions,
      "headers": _vm.questionsHeaders,
      "disable-pagination": "",
      "disable-sort": "",
      "hide-default-footer": ""
    },
    scopedSlots: _vm._u([{
      key: "item.no",
      fn: function (_ref) {
        var item = _ref.item;
        return _c('span', {}, [_vm._v(_vm._s((_vm.page - 1) * _vm.limit + +_vm.questions.indexOf(item) + 1))]);
      }
    }, {
      key: "item.createdAt",
      fn: function (_ref2) {
        var item = _ref2.item;
        return _c('span', {}, [_vm._v(_vm._s(_vm.$dayjs(item.createdAt).format("YYYY-MM-DD HH:mm:ss")))]);
      }
    }, {
      key: "item.answer",
      fn: function (_ref3) {
        var item = _ref3.item;
        return _c('span', {}, [item.answer ? _c('span', [_vm._v("답변")]) : _c('span', [_vm._v("미답변")])]);
      }
    }, {
      key: "item.actions",
      fn: function (_ref4) {
        var item = _ref4.item;
        return _c('span', {}, [_c('v-icon', {
          attrs: {
            "small": ""
          },
          on: {
            "click": function ($event) {
              return _vm.details(item);
            }
          }
        }, [_vm._v(_vm._s(_vm.mdiForum))])], 1);
      }
    }])
  }), _c('v-divider'), _c('v-pagination', {
    staticClass: "mt-6",
    attrs: {
      "length": _vm.pageCount,
      "total-visible": "11"
    },
    on: {
      "input": function ($event) {
        return _vm.search(true);
      }
    },
    model: {
      value: _vm.page,
      callback: function ($$v) {
        _vm.page = $$v;
      },
      expression: "page"
    }
  }), _c('v-layout', {
    staticClass: "ma-1 mt-4",
    attrs: {
      "no-gutters": ""
    }
  }, [_c('v-spacer'), _c('v-btn', {
    staticClass: "py-4",
    attrs: {
      "width": _vm.$vuetify.breakpoint.mobile ? '100%' : 'auto',
      "rounded": "",
      "large": "",
      "color": "primary"
    },
    on: {
      "click": _vm.create
    }
  }, [_c('v-icon', {
    staticClass: "mr-3"
  }, [_vm._v(_vm._s(_vm.mdiChatQuestion))]), _vm._v("작성하기")], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }